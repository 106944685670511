<script setup>
import {CdnImage} from '@apility/vue-netflex-cdn-image'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import AutoplayVideo from './AutoplayVideo.vue'

const props = defineProps({
  theme: {
    type: String,
    default: 'dark',
    validator: (value) => ['dark', 'light'].includes(value),
  },
  headingTag: {
    type: String,
    default: 'h2',
  },
  title: {
    type: [String, null],
    default: null,
  },
  content: {
    type: [String, null],
    default: null,
  },
  image: {
    type: [Object, null],
    default: null,
  },
  video: {
    type: [String, null],
    default: null,
  },
  showCallout: {
    type: Boolean,
    default: false,
  },
  calloutContent: {
    type: [String, null],
    default: null,
  },
  button: {
    type: Object,
    default: () => ({}),
  },
})

const hasVideo = !!props.video
</script>

<template>
  <div
    :class="['PromoCard', `PromoCard--${theme}`]"
  >
    <div class="PromoCard__media">
      <AutoplayVideo
        v-if="hasVideo"
        class="PromoCard__video"
        :video="video"
        :poster-image="image"
      />
      <CdnImage
        v-else-if="image?.path"
        :path="image.path"
        mode="l"
        :breakpoints="{
              DEFAULT: { size: '240x240' },
              xs: { size: '495x495' },
              sm: { size: '420x420' },
              md: { size: '584x584' },
              lg: { size: '444x444' },
              xl: { size: '560x560' },
              xxl: { size: '710x710' },
            }"
        size="710x710"
        :search-params="{ ext: 'webp' }"
        v-slot="{ attrs }"
      >
        <img
          v-bind="attrs"
          :alt="image.altText"
          class="PromoCard__image"
        >
      </CdnImage>

      <div
        v-if="showCallout"
        class="PromoCard__callout"
      >
        {{ calloutContent }}
      </div>
    </div>
    <div class="PromoCard__body">
      <component
        v-if="title"
        :is="headingTag"
        class="PromoCard__heading"
      >
        {{ title }}
      </component>

      <div
        class="PromoCard__content"
        v-html="content"
      />

      <div class="PromoCard__footer">
        <a
          v-if="button?.label && button?.link"
          :href="button.link"
          class="PromoCard__button btn btn-primary"
        >
          {{ button.label }}
          <FontAwesomeIcon
            class="ms-1"
            :icon="['fas', 'angle-right']"
          />
        </a>
      </div>
    </div>
  </div>
</template>
