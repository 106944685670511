<script setup>
import NavigationCards from './NavigationCards.vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {ref} from 'vue'
import PromoCard from './PromoCard.vue'
import AutoplayVideo from './AutoplayVideo.vue'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  target: {
    type: String,
    default: '_self',
  },
  children: {
    type: Array,
    default: () => [],
  },
  dynamicContent: {
    type: Object,
    default: () => ({}),
  },
  dynamicContentType: {
    type: [String, null],
    default: null,
  },
})

const hasDynamicContent = ref(!!props.dynamicContentType && !!props.dynamicContent)
const dynamicContentType = ref(props.dynamicContent?.type)
</script>

<template>
  <div class="MainNavChildNav">
    <div class="MainNavChildNav__container">
      <a
        :href="url"
        :target="target"
        class="MainNavChildNav__link MainNavChildNav__link--heading"
      >
        {{ title }}

        <FontAwesomeIcon
          class="MainNavChildNav__headingIcon"
          icon="fas fa-angle-right"
        ></FontAwesomeIcon>
      </a>

      <ul class="MainNavChildNav__list">
        <li
          v-for="child in children"
          :key="child.id"
          :class="{
              'MainNavChildNav__item': true,
              'MainNavChildNav__item--lowPriority ': child.low_priority,
            }"
        >
          <a
            :href="child.url"
            :target="child.target"
            class="MainNavChildNav__link"
          >
            {{ child.title }}
          </a>
        </li>
      </ul>

      <div
        v-if="hasDynamicContent"
        :class="{
          'MainNavChildNav__dynamicContent': true,
          'MainNavChildNav__dynamicContent--visibleInCollapsedNav': dynamicContent?.visibleInCollapsedNav,
        }"
      >
        <NavigationCards
          v-if="dynamicContentType === 'navigationCards'"
          :title="dynamicContent.title"
          heading-tag="h4"
          :cards="dynamicContent.cards ?? []"
        />
        <PromoCard
          v-if="dynamicContentType === 'promoCard'"
          :theme="dynamicContent.theme"
          :title="dynamicContent.title ?? null"
          heading-tag="h4"
          :content="dynamicContent.content ?? null"
          :image="dynamicContent.image ?? null"
          :video="dynamicContent.video ?? null"
          :show-callout="dynamicContent.showCallout ?? false"
          :callout-content="dynamicContent.calloutContent ?? null"
          :button="dynamicContent.button"
        />
        <div
          v-if="dynamicContentType === 'richText'"
          v-html="dynamicContent.content"
        />
        <template v-if="dynamicContentType === 'video'">
          <div
            v-if="dynamicContent.sourceType === 'external'"
            class="MainNavChildNav__video MainNavChildNav__video--external"
            v-html="dynamicContent.source"
          />
          <AutoplayVideo
            v-else
            class="MainNavChildNav__video"
            :video="dynamicContent.source"
            :poster-image="dynamicContent.image ?? null"
          />
        </template>
      </div>
    </div>
  </div>
</template>
