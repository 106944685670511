{
    "pageHeader": {
        "nav": {
            "close": "Close",
            "open": "Menu"
        },
        "closed": "Closed",
        "openingHoursToday": "Open today",
        "search": {
            "buttonLabel": "Search",
            "close": "Close",
            "heading": "Search",
            "inputPlaceholder": "What are you looking for?",
            "open": "Search"
        }
    },
    "ticketing": {
        "loading": "Loading",
        "date": {
            "select": "Select date",
            "selected": "Selected date",
            "yourSelection": "You have selected",
            "redoSelection": "Pick another date"
        },
        "slot": {
            "select": "Select time",
            "selected": "Selected time",
            "selectDifferent": "Select another time",
            "full": "No tickets available"
        },
        "product": {
            "minimumSelection": "Pick atleast one product",
            "moreInfo": "More information",
            "pricePrefix": "Kr",
            "each": "pcs.",
            "quantity": {
                "decrease": "Decrease number",
                "increase": "Increase number"
            }
        },
        "cart": {
            "add": "Add to cart",
            "adding": "Adding to cart"
        },
        "summary": {
            "total": "Total",
            "willBeAddedToCart": "The following products will be added to your cart",
            "empty": "The cart is empty",
            "removeItem": "Remove product"
        }
    },
    "webshop": {
        "cart": {
            "add": "Add to cart",
            "checkout": "Checkout",
            "close": "Close",
            "heading": "Cart",
            "loading": "Loading",
            "open": "Open cart",
            "removeItem": "Remove item",
            "total": "Total"
        },
        "quantity": {
            "decrease": "Decrease quantity",
            "increase": "Increase quantity"
        },
        "discountSelector": {
            "selectDiscountCode": "Select discount code",
            "selectOrEnterDiscountCode": "Select or enter discount code",
            "enterDiscountCode": "I want to enter my own discount code",
            "discountCode": "Discount code | Discount codes",
            "youSave": "You save NOK {amount}",
            "useCode": "Use code",
            "invalidCode": "The code is invalid"
        }
    },
    "mer": {
        "levels": {
            "1": "Very easy",
            "2": "Easy",
            "3": "Normal",
            "4": "Difficult",
            "5": "Very difficult"
        },
        "filters": {
            "heading": "Filters",
            "subject": "Activity",
            "theme": "Theme",
            "level": "Difficulty"
        },
        "noActivities": "We could not find any activities. Try changing the filters.",
    }
}
