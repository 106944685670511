<script setup>
  import { ref, computed } from 'vue'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
  import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
  import VilViteLogo from './VilViteLogo.vue'
  import MainNav from './MainNav.vue'

  const props = defineProps({
    initialNavOpenState: {
      type: Boolean,
      default: false,
    },
    homePageUrl: {
      type: [String, null],
      default: null,
    },
    mainNavData: {
      type: Array,
      default: () => [],
    },
  })

  const navOpenState = ref(props.initialNavOpenState)
  const headerElement = ref(null)

  const { activate, deactivate } = useFocusTrap(headerElement)

  const toggleNavOpenState = () => {
    navOpenState.value = !navOpenState.value

    if (navOpenState.value) {
      activate()
    } else {
      deactivate()
    }
  }

  const navToggleIcon = computed(() => [
    'fas',
    navOpenState.value ? 'xmark' : 'bars'
  ])
</script>

<template>
  <header
    ref="headerElement"
    class="PageHeader"
    :class="{ 'PageHeader--isOpen': navOpenState }"
  >
    <div class="PageHeader__topBanner">
      <a
        :href="props.homePageUrl"
        class="PageHeader__logo"
        title="VilVite"
      >
        <VilViteLogo />
      </a>

      <button
        class="PageHeader__toggle"
        @click="toggleNavOpenState"
        aria-controls="page-header-collapsible-area"
        :aria-expanded="navOpenState"
      >
        {{ navOpenState ? $t('pageHeader.nav.close') : $t('pageHeader.nav.open') }}
        <FontAwesomeIcon
          class="PageHeader__toggleIcon"
          :icon="navToggleIcon"
          fixed-width
        />
      </button>
    </div>

    <div
      id="page-header-collapsible-area"
      class="PageHeader__collapsibleArea"
    >
      <a
        :href="props.homePageUrl"
        class="PageHeader__logo PageHeader__logo--hiddenWhenCollapsed"
        title="VilVite"
      >
        <VilViteLogo />
      </a>

      <nav class="PageHeader__mainNav">
        <MainNav :mainNavData="props.mainNavData" />
      </nav>

      <div class="PageHeader__supportNav">
        <slot name="support-nav" />
      </div>

      <div class="PageHeader__search">
        <slot name="search" />
      </div>

      <div class="PageHeader__cta">
        <slot name="cta" />
      </div>
    </div>
  </header>
</template>
