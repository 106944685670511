<script setup>
  import { ref } from 'vue'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
  import { FocusTrap } from 'focus-trap-vue'
  import PageHeaderSearchForm from './PageHeaderSearchForm.vue'

  const props = defineProps({
    formAction: {
      type: String,
      required: true,
    },
    queryInputName: {
      type: String,
      default: 'q',
    },
    initialSearchOpenState: {
      type: Boolean,
      default: false,
    },
  })

  const searchOpenState = ref(props.initialSearchOpenState)
  const modalSearchForm = ref(null)

  const openSearch = () => {
    searchOpenState.value = true
  }

  const closeSearch = () => {
    searchOpenState.value = false
  }
</script>

<template>
  <div class="PageHeaderSearch">
    <div class="PageHeaderSearch__inlineForm">
      <PageHeaderSearchForm
        :form-action="formAction"
        :query-input-name="queryInputName"
      />
    </div>

    <button
      class="btn btn-circle PageHeaderSearch__modalToggleButton"
      aria-label="{{ $t('pageHeader.search.open') }}"
      aria-controls="page-header-search"
      :aria-expanded="searchOpenState"
      @click="openSearch"
    >
      <FontAwesomeIcon
        :icon="['far', 'magnifying-glass']"
      />
    </button>

    <Transition name="page-header-search">
      <FocusTrap
        v-model:active="searchOpenState"
        :initial-focus="() => modalSearchForm.searchInputRef"
        fallback-focus="#page-header-search"
      >
        <section
          v-show="searchOpenState"
          class="PageHeaderSearch__modal"
          id="page-header-search"
        >
          <header class="PageHeaderSearch__header">
            <h2 class="PageHeaderSearch__heading">
              <label for="page-header-search-input">
                {{ $t('pageHeader.search.heading') }}
              </label>
            </h2>

            <button
              class="PageHeaderSearch__closeButton"
              aria-controls="page-header-search"
              @click="closeSearch"
            >
              {{ $t('pageHeader.search.close') }}
              <FontAwesomeIcon
                class="ms-1"
                :icon="['fas', 'xmark']"
                fixed-width
              />
            </button>
          </header>

          <PageHeaderSearchForm
            ref="modalSearchForm"
            :form-action="formAction"
            :query-input-name="queryInputName"
          />
        </section>
      </FocusTrap>
    </Transition>
  </div>
</template>
