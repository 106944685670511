<script setup>
import {ref} from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import MainNavChildNav from './MainNavChildNav.vue'

const props = defineProps({
  tagName: {
    type: String,
    default: 'li',
  },
  navDataItem: {
    type: Object,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['toggleActiveState'])

const url = ref(props.navDataItem.url)
const target = ref(props.navDataItem.target)
const title = ref(props.navDataItem.title)
const id = ref(props.navDataItem.id)
const children = ref(props.navDataItem.children)
const hasChildren = ref(children.value && children.value.length)
const dynamicContent = ref(props.navDataItem.dynamic_content)
const dynamicContentType = ref(dynamicContent?.value?.type)
</script>

<template>
  <component
    :is="tagName"
    class="MainNav__item"
  >
    <button
      v-if="hasChildren"
      :class="{
        'MainNav__link': true,
        'MainNav__link--active': isActive,
      }"
      :aria-controls="`child-nav-${id}`"
      :aria-expanded="isActive ? 'true' : null"
      @click="$emit('toggleActiveState', id)"
    >
      {{ title }}

      <FontAwesomeIcon
        class="MainNav__icon"
        icon="fas fa-angle-down"
      ></FontAwesomeIcon>
    </button>
    <a
      v-else
      :href="url"
      :target="target"
      class="MainNav__link"
    >
      {{ title }}
    </a>

    <Transition name="main-nav-child-nav">
      <MainNavChildNav
        v-if="hasChildren && isActive"
        :id="`child-nav-${id}`"
        :title="title"
        :url="url"
        :target="target"
        :children="children"
        :dynamic-content="dynamicContent"
        :dynamic-content-type="dynamicContentType"
      />
    </Transition>
  </component>
</template>
