<script setup>
import {createCdnMediaUrl, useCdnImage } from '@apility/vue-netflex-cdn-image'

const props = defineProps({
  posterImage: {
    type: [Object, null],
    default: null,
  },
  video: {
    type: String,
    required: true,
  },
})

const { cdnUrl } = useCdnImage()
const videoFileFormat = props.video.split('.').pop() ?? 'mp4'
</script>

<template>
  <video
    playsinline
    autoplay
    loop
    muted
    :poster="posterImage?.path ? createCdnMediaUrl(cdnUrl, posterImage?.path, 'rc', [1280, 720]) + '?compressor=lambda&ext=webp' : null"
  >
    <source
      :src="video"
      :type="`video/${videoFileFormat}`"
    />
  </video>
</template>
