<script setup>
  import {ref} from 'vue'
  import MainNavItem from './MainNavItem.vue'

  const props = defineProps({
    mainNavData: {
      type: Array,
      default: () => [],
    },
  })

  const activeNavItem = ref(null)

  const toggleActiveState = (id) => {
    activeNavItem.value = activeNavItem.value === id ? null : id
  }
</script>

<template>
  <ul class="MainNav">
    <MainNavItem
      tag-name="li"
      v-for="item in mainNavData"
      :key="item.id"
      :nav-data-item="item"
      :is-active="item.id === activeNavItem"
      @toggle-active-state="toggleActiveState"
    />
  </ul>
</template>
