<script setup>
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useOrder from '../../ticketing/composables/order'

const { cartOpen: cartOpenState, order } = useOrder()


const showCartButton = computed(() => !!order.value.cart?.length)

const openCart = () => {
  cartOpenState.value = true
}

const cartTotalItems = computed(() => {
  let count = 0

  order.value.cart?.forEach(item => {
    count = count + item.quantity
  })

  return count
})

const icon = computed(() => {
  if (cartTotalItems.value >= 99) {
    return 'docker'
  } else if (cartTotalItems.value > 68) {
    return 'ferry'
  } else if (cartTotalItems.value > 50) {
    return 'truck-container'
  } else if (cartTotalItems.value > 25) {
    return 'truck'
  } else if (cartTotalItems.value > 15) {
    return 'cart-flatbed-boxes'
  } else if (cartTotalItems.value > 5) {
    return 'cart-shopping'
  }

  return 'basket-shopping'
})
</script>

<template>
  <Transition name="webshop-cart-button">
    <button
      class="WebshopCartButton"
      aria-controls="webshop-cart"
      :aria-expanded="cartOpenState"
      :aria-label="$t('webshop.cart.open')"
      @click="openCart"
      v-if="showCartButton"
      :data-total-items="cartTotalItems > 99 ? '99+' : cartTotalItems"
    >
      <FontAwesomeIcon :icon="['far', icon]" />
    </button>
  </Transition>
</template>
