import {defineAsyncComponent} from 'vue'
import {enUS, nb} from 'date-fns/locale'

export default {
  install(app) {
    let locales = {
      nb,
      en: enUS
    }

    let localeString = (document.querySelector("html").getAttribute('lang') ?? 'en') === 'nb' ? 'nb' : 'en'

    app.component('v-change-dates', defineAsyncComponent(() => (import('./components/ChangeDates.vue'))))
    app.config.globalProperties.$locale = locales[localeString]
  }
}
