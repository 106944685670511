<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {ref} from 'vue'

const props = defineProps({
  formAction: {
    type: String,
    required: true,
  },
  queryInputName: {
    type: String,
    default: 'q',
  },
})

const searchInputRef = ref(null)

defineExpose({
  searchInputRef,
})
</script>

<template>
  <form
    :action="formAction"
    class="PageHeaderSearchForm"
  >
    <input
      type="search"
      class="PageHeaderSearchForm__input form-control"
      id="page-header-search-input"
      :placeholder="$t('pageHeader.search.inputPlaceholder')"
      :name="queryInputName"
      ref="searchInputRef"
    >

    <button
      type="submit"
      class="btn btn-primary PageHeaderSearchForm__submitButton"
    >
      <span class="PageHeaderSearchForm__buttonLabel">
        {{ $t('pageHeader.search.buttonLabel') }}
      </span>
      <FontAwesomeIcon
        class="PageHeaderSearchForm__buttonIcon"
        :icon="['far', 'magnifying-glass']"
      />
    </button>
  </form>
</template>
